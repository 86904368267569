<template>
	<v-dialog v-model="violation" persistent max-width="600px">
		<v-card :loading="loading">
			<v-card-title>
				<span class="headline">Сообщение модератору по поводу видео</span>
			</v-card-title>
			<v-card-text>
				<v-textarea
					outlined
					auto-grow
					v-model="violationText"
					autofocus
					:rules="[rule_required, rule_length_min(10)]"
				/>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="red" text @click="close">Закрыть</v-btn>
				<v-btn color="green" text @click="violationSave">Сохранить</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>
<script>
import { Validator } from "../../mixins/Validator";

export default {
	props: ["video"],
	mixins: [Validator],
	data() {
		return {
			violation: false,
			violationText: "",
			loading: false
		};
	},
	methods: {
		openDialog() {
			this.violation = true;
		},
		close() {
			this.$emit("onClose");
			this.violation = false;
		},
		violationSave() {
			this.loading = true;
			window.ajax(
				"/api/save-violation",
				{
					body: {
						text: this.violationText,
						video_id: this.video.v_id,
						src: this.video.url1
					}
				},
				(data, error) => {
					this.loading = false;
					if (!data.error && !error) {
						this.close();
						this.$emit("onSave", "Ваше обращение отправлено");
					}
				}
			);
		}
	}
};
</script>
