var emit = (vnode, name, data) => {
	var handlers = (vnode.data && vnode.data.on) ||
		(vnode.componentOptions && vnode.componentOptions.listeners);

	if (handlers && handlers[name]) {
		handlers[name].fns(data);
	}
}

var to_expand = true, default_height = 196;

export default {
	inserted: function (el, binding, vnode) {
			function calcHeight() {
				const currentState = el.getAttribute('aria-expanded')

				el.classList.add('u-no-transition')
				el.removeAttribute('aria-expanded')
				el.style.height = null

				if (el.clientHeight <= default_height && to_expand) {
					to_expand = false;
				} else if (el.clientHeight > default_height && !to_expand) {
					to_expand = true;
				}
				emit(vnode, 'status', to_expand);

				el.style.height = el.clientHeight + 'px'
				el.setAttribute('aria-expanded', currentState)

				setTimeout(function () {
					el.classList.remove('u-no-transition')
				})
			}

		if (binding.value !== null) {
			el.classList.add('expand')
			el.setAttribute('aria-expanded', (binding.value && to_expand) ? 'true' : 'false')
			calcHeight();
			setTimeout(calcHeight, 1000); // на случай загрузки картинок
			window.addEventListener('resize', calcHeight)
		}
	},
	update: function (el, binding) {
		if (el.style.height && binding.value !== null) {
			el.setAttribute('aria-expanded', (binding.value && to_expand) ? 'true' : 'false')
		}
	},
}
