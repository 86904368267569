<template>
	<div v-if="description || description_extra">
		<div class="expand" v-expand="isExpanded" @status="expandStatus">
			<div class="gradient" v-if="toExpand"></div>
			<p v-if="description" v-html="description"></p>
			<p v-if="description_extra" v-html="description_extra"></p>
		</div>
		<div class="box-footer text-center" v-if="toExpand">
			<v-btn outlined @click="isExpanded = !isExpanded">{{ isExpanded ? 'Спрятать' : 'Показать описание' }}</v-btn>
		</div>
	</div>
</template>

<script>
import Expand from "../plugins/expand";

export default {
	props: ["description", "description_extra"],
	directives: { Expand },
	data() {
		return {
			isExpanded: false,
			toExpand: true
		};
	},
	methods: {
		expandStatus(val) {
			this.toExpand = val;
		}
	}
};
</script>

<style lang="scss" scoped>
.expand {
	position: relative;
	overflow: hidden;
	transition-property: height;
	transition-duration: .4s; // Durations can be changed without touching JS
	transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);

	&[aria-expanded="false"] {
		transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);

		.gradient {
			position: absolute;
			bottom: 0px;
			width: 100%;
			height: 100px;
			background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #fff 100%) repeat-x;
			pointer-events: none;
		}
	}
}
.theme--dark .expand {
	&[aria-expanded="false"] {
		.gradient {
			background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #424242 100%) repeat-x;
		}
	}
}

.u-no-transition {
	transition-duration: 0s !important;
}
</style>

